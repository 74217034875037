export enum CorrLanguage {
  'en-CA' = 1,
  'fr-CA' = 2,
  'es-ES' = 3,
  'de-DE' = 4,
}

export enum LanguagesEnum {
  EN_CA = 'en-CA',
  FR_CA = 'fr-CA',
  ES_ES = 'es-ES',
  DE_DE = 'de-DE',
}
